import React from 'react'
import final from './final.png'
import finalSettings from './final-settings.png'
import secondSplash from './second-splash.png'
import firstSplsah from './first-splash.png'

export default function (props) {
	return (<div>
		<h1>
			Lesson 4: Becoming self-sufficient
		</h1>
		<p><u>Start your five minute timer now.</u></p>
		<h4>First install pyCharm:</h4>
		<p>
			<a href="https://www.jetbrains.com/pycharm/download/#section=mac">Mac</a>
		</p>
		<p>
			<a href="https://www.jetbrains.com/pycharm/download/#section=windows">Windows</a>
		</p>
		<p>
			<a href='https://www.jetbrains.com/pycharm/download/#section=linux'>Linux</a>
		</p>

		<h3>Install python:</h3>
		<p>
			<a href='https://www.python.org/ftp/python/3.7.3/python-3.7.3-amd64.exe'>Windows</a>
		</p>
		<p>
			<a href='https://www.python.org/ftp/python/3.7.3/python-3.7.3-macosx10.9.pkg'>Mac</a>
		</p>
		<p>
			Linux, run:
			<br />
			<p className='code'>sudo apt-get install python3.6</p>
		</p>

		<p>
			Just install those two like you install any program.
		</p>
		<p>
			Alright. Done. Stop the timer if it’s still running. You just beat yourself.
			You beat the possibility of giving up, and did the work instead.
		</p>

		<p>
			If you still haven’t installed it. Just do it now. It takes literally less than five minutes. Otherwise stop
			reading and come back when you are ready. I’ll be waiting right here. It’s okay to quit - but don’t fool
			yourself and act like you didn’t.
		</p>

		<h2>Setting up a project</h2>
		<p>
			Before we can start hacking, we will create a ‘project’. It’s a way to group files that are related to a
			specific project together.
		</p>

		<p>
			Open PyCharm.
		</p>
		<p>
			Click the ‘New Project’
		</p>
		<img src={firstSplsah} />
		<p>
			Change the project location, so the final folder is called napoleon-hello
		</p>
		<img src={secondSplash} />
		<p>
			The settings should look similar to this:
		</p>
		<p>
			Click Create
		</p>
		<img src={finalSettings} />
		<p>
			After a little loading, your screen should look like this:
		</p>
		<img src={final} />
		<p>
			Now we have a new project up and running.
			In the left side of the screen you can see our files.
			You can open the folder called 'napoleon-hello' and see the file 'main.py'.
		</p>
		<p>
			We will write our program inside this file.
		</p>
		<p>
			In the top right corner you can see several buttons. For now you only need to know one.
			They play button.
			The play button starts the program.
		</p>
		<p>
			When you start the program, a 'terminal' will appear.
			Inside this terminal, you can read the output of the program.
			We will visit this topic again later.
		</p>
	</div>)
}
