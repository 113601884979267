import React from 'react'

export default function (props) {
    return (
        <div className='lesson'>
            <h1>Lesson 2: Code</h1>
            <h5>Written by Bjørn Kaae</h5>
            <p>
                This lesson will introduce you to code and programming languages.
                Why do we need more than one language? And why can't we just use english?
            </p>
            <p>
                Code is text. It’s instructions that computers run 1 line at a time.
                There is nothing really special about code. You can write code in any program. But you need a special
                program to ‘execute’ that code.
            </p>
            <h3>Programming languages</h3>
            <p>
                All languages are used for communicating ideas. The difference between a normal language and a
                programming language is smaller than you realise.
            </p>
            <p>
                It all comes down to what they are ‘optimized’ for.
            </p>
            <p>
                Normal languages are excellent for communicating abstract ideas. We can construct entire magical
                kingdoms and wars with English in very few words.
            </p>
            <div className='quote'>
                <p>“FOR SALE, BABY SHOES, NEVER WORN.”</p>
                <h4>- Ernest Hemmingway</h4>
            </div>
            <p>
                A complete story with beginning, middle and end. <b>Six</b> words.
            </p>
            <br/>
            <p>
                On the other hand. Try to use English for an ikea assembly guideline, without images. You’re going to
                end up with more broken couples than assembled beds at the end of the day.
            </p>
            <div className='quote'>
                <p>
                    “First open up the three boxes. Take the screws from box 1 and the tool you can find in any of the
                    boxes. Then set the longest board from box two on it’s side and screw in the thickest screw from box
                    1...”
                </p>
                <h4>- Definitely not Hemmingway</h4>
            </div>

            <p>
                It’s basically impossible. It's not what English is good at.
            </p>
            <p>
                Ikea assembly guides are on the contrary very good at communicating these ideas.
            </p>

            <h4>Programming Languages</h4>
            <p>
                Programming languages communicate ideas to computers.
                The most basic programming languages are very complicated for humans to write and understand.
            </p>
            <p>
                Surprisingly these languages are still being used.
                The most basic of these is a language called assembly.
                Assembly can run directly on the CPU of a computer.
                That means it can be directly translated to electrical current and be used for calculations.
                It’s the simplest possible building block in programming.
            </p>
            <p>
                When you write assembly manually you have to track everything. To calculate something simple like, ‘what
                day is it?’ requires thousands and thousands of lines of code.
            </p>
            <p>
                We will get to how time calculations are done later. It’s quite fascinating. We have a tiny machine in
                all our computers (and phones) that count the amount of milliseconds since first of january 1970. No
                lie.
            </p>
            <p>
                Right now that number is <p className='code'>1595831591.0388696</p>
            </p>
            <p>
                But anyway.. You don’t have to know assembly. Because some smart people made a language on-top of
                assembly. This language is a little more english-ish: <b>Python</b>. And the smart part is, this
                language can be translated into assembly.
            </p>
            <p>
                Think back to the sub-recipes we created for Walt.
                “Assembly” is comparable to the basic language we used to talk with Walt.
                Our sub-recipes is a programming language ‘on top’ of Waltassembly.
                It allows us to talk with him in our own language.
            </p>
            <p>
                By using python, we can forget a lot of what actually happens on the computer.
                And instead learn how python works.
                We will introduce the necessary concepts when you are more familiar with programming.
                But that’s not important right now. Now let's talk about Python!
            </p>

            <h4>Python</h4>
            <p>
                Since we have this easy-to-use programing language, we can for example write:
            </p>
            <p className='code'>
                a = 14
            </p>
            <p>
                And the program “python.exe” will translate that into about 4 lines of assembly code and then run it on
                your CPU.
            </p>
            <p>
                To calculate what day it is in python:
            </p>
            <p className='code'>
                now().weekday()
            </p>
            <p>
                Single line of python, which translates to thousands of lines of assembly code behind the scenes. It
                takes a couple hundred nanoseconds to calculate.
            </p>
            <h4>Why is there more than 1 language?</h4>
            <p>
                The answer is pretty simple. Some languages are good for displaying images of cats, while others are
                good for sending stuff to the moon. But it’s important to know that all languages will be able to do
                what the other can.
            </p>
            <p>
                French is good for talking about food and love. German is good for engineering. Danish for potatoes. But
                they can all communicate the same ideas if they wanted to.
            </p>
            <p>
                When you become more familiar with programming it will be easier to understand the difference between
                languages. But trust me - python is a great place to start.
            </p>
            <p>
                This was a short introduction to programming languages. Now we’ll get on to some actual code.
            </p>
        </div>)
}
