import React, {useState} from 'react';
import './App.css';
import Menu from "../menu/Menu";
import ContactMePopup from "../contact-me-popup/ContactMePopup";
import PopoutMenu from "../popout-menu/PopoutMenu";
import Footer from "../footer/Footer";

function App(props) {
    const [displayContactMe, setDisplayContactMe] = useState(false)
    const [popoutMenu, setPopoutMenu] = useState(false)

    return (
        <div className="App">
            <Menu className='menu' openPopout={() => setPopoutMenu(true)} />
            {props.children}
            {displayContactMe ? <ContactMePopup close={() => setDisplayContactMe(false)} /> : null}
            {popoutMenu ? <PopoutMenu close={() => setPopoutMenu(false)} /> : null}
            <div className='stretch'><nbsp /></div>
            <Footer className='footer' openContactMe={() => setDisplayContactMe(true)} />
        </div>
    );
}

export default App
