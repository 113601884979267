import React from 'react'
import './popout-menu.css'

export default function (props) {
    return (<div className='wrapper' onClick={props.close}>
        <div className='PopoutMenu' onClick={e => e.stopPropagation()}>
            <h3>
                You're here to find something. That's as good as pancakes to me
            </h3>
            <ul>
                <li>About</li>
                <li>Solve your problem</li>
                <li>Blog</li>
                <li>Hobbies and other things</li>
            </ul>
            <div className='filler'/>
        </div>
    </div>)
}