import React from 'react';
import ReactDOM from 'react-dom';
import {Route, BrowserRouter, Switch} from "react-router-dom";

import './index.css';
import * as serviceWorker from './serviceWorker';
import LandingPage from "./pages/landing-page/LandingPage";
import AboutMe from "./pages/about-me/AboutMe";
import App from "./pages/app/App";
import WhyLearn from "./pages/why-we-learn/WhyLearn";

const app = (<BrowserRouter>
    <App>
        <Switch>
            <Route exact path="/" component={LandingPage}/>
            <Route exact path="/lessons" component={LandingPage}/>
            <Route exact path="/lessons/:lessonId" component={LandingPage}/>
            <Route exact path="/about" component={AboutMe}/>
            <Route exact path="/why" component={WhyLearn}/>
        </Switch>
    </App>
</BrowserRouter>)


ReactDOM.render(
    app,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
