import React from 'react'
import './footer.css'
import {withRouter} from "react-router-dom";

function Footer(props) {
    return (<div className='Footer'>
        <p>
            Bjørn Kaae, software developer
        </p>
        <p>
            <input type='button' value='Contact Information' onClick={props.openContactMe}/>
        </p>
    </div>)
}

export default withRouter(Footer)
