import React from 'react'

export default function (props) {
	return (<div>
		<h1>Lesson 3.5: Becoming active</h1>

		<p>
			In the next lesson you will make your first program.
		</p>

		<p>
			This is the moment of max resistance in your quest to becoming a programmer.

		</p>
		<p>You have to install a program that lets you execute code on your own machine. You’re installing the python
			programming language.
		</p>

		<p>
			That resistance that you are feeling is insecurity. An idea that this next part might be hard or over your
			head.
		</p>
		<p>
			But don’t worry. I have a small hack we can use to get you through it.
		</p>
		<p>
			Before starting the next lesson, take your phone and set a timer for 5 minutes.
		</p>
		<p>
			In the next chapter, when I ask you to install a program, start that timer. Now promise yourself to quit
			when the timer rings.
		</p>

		<p>
			That’s it.

		</p>
		<p>
			Tell yourself that you will give it <b>five</b> minutes. That’s nothing! Even if it takes longer than five minutes
			-
			you will still finish because it’s not as hard as it felt.
		</p>
		<p>
			I wrote this chapter to help you get past this stupid little hump. This is when most people stop learning.
		</p>
		<p>
			Now go go go! No stopping
		</p>
	</div>)
}
