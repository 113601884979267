import React from 'react'

export default function (props) {
    return (<div>
        <h4>How to make the world better</h4>
        <p>
            There are a lot of ways to change the world. Programming is one direct way to use your brain and the social
            net afforded to us by our western governments.
        </p>
        <p>
            I want to introduce you to the concept of ‘open source’.
        </p>
        <p>
            If a project is open source it means the source code is open for anyone to download. Like wikipedia is free
            to read and anyone can edit - open source projects are created and maintained by individuals.
        </p>
        <p>
            Programming is a community. And not just a single community, a vast community of proportions we can’t
            fathom. There are large open-source projects, but more importantly there are thousands of tiny projects.
            Each project has dreams and motivations built into them. Some are just getting started, some are already
            running.
        </p>
        <p>
            The exciting thing is that you can join any team in the world.
        </p>
        <p>
            There are open-source projects for building wind-energy farms in Africa. There are projects for developing
            open-source facebook. Open source youtube. Open source self-driving cars. Open source ‘vertical farms’.
            Almost anything you can imagine.
        </p>
        <p>
            Programming being what it is, you don’t need to have a degree to contribute. You can open your laptop on the
            train. Open the buglist of your favorite project. Locate a bug that seems possible. Solve it. A day or two
            later, people from across the globe will give you feedback, and then have your code added to the final
            project.
        </p>
        <p>
            Okay. But not to sound dickish, what about me? Can I get some of that internet money?
        </p>
        <h4>Get that cash</h4>
        <p>
            To make money in programming, you need to know two things.
        </p>
        <ul>
            <li>Api stuff</li>
            <li>Database stuff.</li>
        </ul>
        <p>
            All companies use an API and a database in some form. It’s two cornerstones of any it-solution. The reason
            this is a good place to start is not because it’s easy to do, but because it’s a limited scope. It’s enough
            to know about these two areas to be useful.
        </p>
        <p>
            But to do that, you need to be able to code. All the stuff you learn in the course is directly usable for
            coding APIs.
        </p>
    </div>)
}
