import React from 'react'
import './contact-me-popup.css'
import closeIcon from './icons8-delete.svg'

export default function (props) {
    return (<div className='wrapper' onClick={props.close}>
        <div className='ContactMePopup' onClick={e => e.stopPropagation()}>
            <div className='title-wrapper'>
                <h3>Walking around.. and suddenly <i>you</i> give a reason to smile!</h3>
                <img src={closeIcon} onClick={props.close} />
            </div>
            <label>Write Me</label>
            <p>bjornkaae@gmail.com</p>
            <label>Text or call me</label>
            <p>+45 22572045</p>
        </div>
    </div>)
}
