import React from 'react'
import DividedOperator from '../divided-operator/divided-operator.js'

export default function (props) {
	return (<div>
		<h1>How to solve tasks</h1>
		<p>On the surface, Walt’s commands seemed very simple. But actually completing a simple command, leads to more
			subtasks that you first realise.</p>

		<p>Let’s take a look at a normal human task, to see how many subtasks is actually conveyed in a single
			sentence.</p>

		<p>“Go buy some bread”. You immediately understand all the steps between now and finishing the task. You start
			putting on pants and moving outside. Before you do that, you realise you need your wallet. You also need
			your car keys. And house keys. And shoes. Oh, and probably deodorant? “When was the last time I
			showered..”</p>

		<p>Lying in your bed, you will not know all the steps. But your brain knows. And your brain will remind you of
			all the necessary steps as you get started. It will feed your consciousness with the needed steps as you
			move along.</p>

		<p>You’ve probably never thought about the order of the tasks that you perform. But you realise the importance
			when you accidentally make a mistake. For example going outside without putting on shoes. Or forgetting to
			preheat the oven when making dinner.</p>

		<p>Humans are not too different from computers. If we were to lay out the steps that our brains take to come up
			with actionable tasks, it would be similar to the computer.</p>

		<p>Imagine putting on a blindfold and having a friend tell you what to do. Every step, every movement of your
			hand. Everything will be spelled out in excruciating detail. Without the blindfold all these subtasks are
			automatically generated in your skull and put into action. But having another person explain what needs to
			be done, shows clearly how many tasks are actually needed.</p>

		<p>Let’s look at how computers understand and then complete tasks.</p>

		<p>At first glance, when you run a program, a computer will immediately understand and perform the tasks you
			give it. First it takes the command as a whole and then splits it up into small tasks. Each task can then be
			performed without any other knowledge than the task at hand.</p>

		<p>This splitting of complex tasks is called dividing. Solving each task is called Conquering.</p>

		<p>Divide and Conquer.</p>
		<p>Let’s take a look at how a computer actually understands code.</p>

		<p className='code'>a = 5 + 3</p>

		<p>First the computer looks at the operators. We have two: ‘=’ and ‘+’.</p>

		<p>These operators are ‘binary’, because they have two inputs. The computer will only be able to ‘conquer’ an
			operator when the two inputs are clearly defined values. A clearly defined value is a number or text. An
			unclearly defined value could be a variable name. Or in this case, “5 + 3” is not clearly defined - because
			it’s not just a number.</p>

		<p>For the equal operator, the computer needs two inputs: a variable name and a value. We divide the command
			around the equal-sign operator:</p>

		<DividedOperator operator='=' leftSide='a' rightSide='5 + 3'/>

		<p>Left side is as simple as it gets. We can’t divide ‘a’ into a smaller task. We have one input ready for the
			equal operator. But the right hand side still needs to be conquered. So we divide the right hand side around
			the plus-operator.</p>

		<DividedOperator operator='+' leftSide='5' rightSide='3' />

		<p>Now we have the problem split into small parts. Next job is to conquer.</p>

		<h3>Conquer</h3>
		<p>“If I had an hour to solve a problem I'd spend 55 minutes thinking about the problem and 5 minutes thinking
			about solutions.” - Einstein</p>

		<p>Most of the work lies in dividing the tasks. When they are divided it’s just about rebuilding the original
			command.</p>

		<p>The operator “+” needs two values: the two numbers we add together.</p>
		<p>By dividing we found the values to be 5 and 3. The result of conquering the plus command becomes 8.</p>

		<p>The operator ‘=’ also takes two arguments. A variable name and the value to assign it.</p>

		<p>The name was ‘a’ and the value we have calculated to be 8. So our simplified commands becomes:</p>
		<p className='code'>a = 8</p>

		<p>This program doesn’t do much. What you should take away from this example is how the computer solves a
			task.</p>

		<h3>Conclusion</h3>
		<p>What we are actually doing here is bridging the gap between how a human wants to write commands and how a
			computer understands those commands.</p>

		<p>We are translating code written in a programming language into commands the computer can solve. Like
			explaining to your blindfolded friend how to find his shoes, even though the actual task is ‘go buy
			bread’.</p>
	</div>)
}
