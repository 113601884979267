import React from 'react'
import './menu.css'
import {Link, useLocation} from "react-router-dom";

function Menu(props) {
    let location = useLocation();
    React.useEffect(() => {}, [location]);
    let {pathname} = location

    const paths = [{
        route: '/lessons/1',
        isSelectedMatch: '/lessons',
        title: 'Introduction to programming'
    }, {
        route: '/why',
        isSelectedMatch: '/why',
        title: 'Why Learn to Code'
    }, {
        route: '/about',
        isSelectedMatch: '/about',
        title: 'About me'
    }]

    return (<div className='Menu'>
        <div className='center'>
            {paths.map(p => (<Link
                    key={p.route}
                    className={pathname.startsWith(p.isSelectedMatch) ? 'selected' : ''}
                    to={p.route}>{p.title}
                </Link>)
            )}
        </div>
    </div>)
}
export default Menu
