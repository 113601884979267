import React from "react";
import './divided-operator.css';

export default function(props) {
	const {operator, leftSide, rightSide} = props
	return (
		<div className='divided-operator'>
			<h3 className='function'>Operator: {operator}</h3>

			<table className='function-table'>
				<thead>
				<tr>
					<th>Left</th>
					<th>Right</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>{leftSide}</td>
					<td>{rightSide}</td>
				</tr>
				</tbody>
			</table>
		</div>)
}
