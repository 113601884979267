import React from "react"

export default function (props) {
    return (<div className='lesson'>
            <h1>Lesson 1: please don't panic</h1>
            <h5>Written by Bjørn Kaae</h5>
            <p>I will teach you to program better than Bill Gates.
                That’s not a stretch.
                He’s probably pretty rusty by now.</p>
            <p>
                We will replace the weird and confusing mental model you have right now.
                It’s really not helping you.
            </p>
            <p>
                Programming is hard. But not for the reasons you think. Math is rarely needed, and when it is, it’s
                simple. You don’t need to be super smart or spend years learning.
            </p>
            <p>
                The real reason is that new programmers try to learn two things simultaneously -
                thinking and coding.
            </p>
            <p>
                <b>Thinking</b> means breaking the problem into smaller pieces and solving them one at a time,
                focusing on the details and using a methodical approach.
            </p>
            <p>
                <b>Coding</b> is simply a language to express a chain of logic.
                It’s a lot simpler to code, if you already know what you want to say.
            </p>
            <p>
                That’s why you will learn the detailed rules (coding) of the game later.
                In this course, you will first learn to solve problems, and then a little later, you will learn code.
            </p>
            <p>
                There are several ways you can learn to fly a plane.
            </p>
            <p>
                One way would be to throw you inside a plane and: “THIS IS THE RUDDER, IT STEERS THE PLANE. WELCOME
                TO
                INTRO TO PLANES, MY NAME IS JOHN, TODAY WE-- LOOK OUT!”
            </p>
            <p>
                I mean.. That’s awesome. Flying a plane on your first day! But arguably, it gives the wrong
                impression of flying.
            </p>
            <p>
                I believe learning to program is a process.
                Programming requires you to think differently, not just learn something new.
            </p>
            <h4>Walt</h4>
            <p>
                Meet Walt, the person.
                Plain shirt, pants, white socks and a blue cap. “Hello!”.
                He will do what it says on the piece of paper in front of him.
                He starts at the top and then moves down one step at a time.
                The piece of paper is called the recipe.
            </p>
            <p>
                Walt will only follow the recipe, if everything is perfect.
                If the recipe calls for flour, and the flour pot is empty, Walt starts crying.
                After you replace the flour pot, you can ask him to start from the top again.
            </p>

            <p>
                Walt can do simple tasks: move things, add things to the bowl and read commands from the recipe.
                “add salt” is a terrible command, since Walt can't determining the amount of salt.
                Only thing added is tears.
            </p>
            <p>
                It can be a lot of work to finish a recipe.
                But the recipe can be used as many times as you like.
                And you can be sure that the result will be perfect every time.
            </p>

            <h4>
                Writing a recipe
            </h4>
            <p>
                You must understand Walt's environment, to write a Walt compatible recipe.
                He has a table.
                On his right there is an electronic scale, on the left he has his recipe, center is his bowl.
                Above the table, on the wall, there is a shelf with all the ingredients needed in the recipe.
                Underneath the shelf hangs tools, like measuring cups and spoons for stirring.
            </p>
            <p>
                Only a limited amount of things are allowed on the table:
                The bowl, the scale and his recipe.
                </p>
            <p>
                He can only hold two things - for example the flour pot under his arm and a measuring cup in the other hand.
                If you ask him to pick up a third thing,
            </p>

            <h4>How to add water</h4>
            <p>
                Walts recipe:
            </p>
            <ul>
                <li>Pick up measuring cup from tools</li>
                <li>Place measuring cup on the scale</li>
                <li>Turn on the scale</li>
                <li>Pick up water from ingredients</li>
                <li>Add 200 gram water to measuring cup on the scale</li>
                <li>Place water in ingredients</li>
                <li>Pick up measuring cup from the scale</li>
                <li>Pour from measuring cup into the bowl</li>
                <li>Place measuring cup in tools</li>
                <li>Turn off scale</li>
            </ul>

            <p>Phew.. All that work to add water.</p>

            <h4>Simplifying</h4>
            <p>
                It's hard to keep track of the changes in his environment.
                While writing the recipe you must remember what he has in his hands, what’s on the scale
                <i> and</i> the overall goal of the recipe.
            </p>
            <p>
                It takes 10 steps to add water.
                So to write a complete recipe, with several ingredients, we need need a tool.
            </p>
            <p>
                Let's make a 'subrecipe'.
            </p>
            <p>
                A subrecipe is a recipe meant to be used in other recipes.
                It explains how to do a specific thing. Instead of the whole process.
                We can create a subrecipe from the explanation for adding water.
                And then use that subrecipe in other recipes.
            </p>
            <p>
                Furthermore, we can make the recipe more general.
                Instead of just being able to add water, we strike out the specifics:
                ‘200 gram’ and ‘water’, and then print 50 copies of this empty subrecipe.
            </p>
            <p>
                Next time you need to add something, you grab a copy of the ‘Add stuff to bowl’-copy.
                Fill in the two things you have to change: ingredient and amount.
                Now you can add a lot of ingredients in no time.
            </p>
            <p>
                Recipe stays simple to make and Walt won’t even realise.
            </p>

            <br/>
            <h4>Book of recipes</h4>
            <p>
                We need to do other things than just add ingredients.
                Therefore we should create some more subrecipes for things we know we need.
            </p>
            <ul>
                <li>Mix the ingredients</li>
                <li>Sweep the floor</li>
                <li>Clean the bowl</li>
                <li>Make buns and add to baking sheet</li>
                <li>Put on gloves and take out bread</li>
            </ul>
            <p>
                Let's keep these subrecipes in a book.
                The next time you want to create a new recipe, it’s a simple matter of picking out the right subrecipes,
                filling them out and taping them together.
            </p>
            <br/>
            <h4>Computer</h4>
            <p>
                Walt is the computer. The recipe is code.
            </p>
            <h4>End of chapter</h4>
            <p>
                Code is on the menu for next chapter.
                What does code actually look like, and why do we have more than one language?
            </p>
        </div>
    )
}
