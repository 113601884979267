import React from "react";
import './function-description.css'


export default function(props) {
    const {name, parameters, returnValue, action, description} = props
    const arrow = <span className='arrow'><u>---></u></span>
    return (
        <div className='function-description'>
            <h3 className='function'>{name}</h3>
            <i>{description}</i>

            <table className='function-table'>
                <thead>
                <tr>
                    <th>Parameters</th>
                    <th>Call</th>
                    {action && <th>Run</th>}
                    {action && <th>Return</th>}
                    <th>Result</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{parameters[0]}</td>
                    <td>{arrow}</td>
                    {action && <td>{action}</td>}
                    {action && <td>{arrow}</td>}
                    <td>{returnValue}</td>
                </tr>
                </tbody>
            </table>
        </div>)
}
