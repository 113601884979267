import React from "react";
import {Link} from "react-router-dom";
import './about-me.css'

export default function AboutMe(props) {
    return (<div className='about-me'>
            <h3>Who am I</h3>
            <p>
                Bjørn Kaae! Nice to meet you. I’m Danish and I live in Copenhagen.
            </p>
            <p>
                I have ADHD. Or at least I <i>used to</i> have it.
                I was diagnosed 10 years ago, but I have learned to overcome the obstacles it brings.
                Unless you have ADHD, you don’t care about that.
            </p>
            <p>
                But maybe you know the feeling of being overwhelmed, seeming stupid and giving up before starting.
            </p>
            <p>
                I know that feeling very well.
                It was a daily routine for the first 18 years of my life.
                But I now realise it makes you stronger to know you aren’t perfect.
                Failing is the only way to get better.
                I can’t believe how proud I feel when people actually start something.
                I normally keep that to myself, because I realised it can seem elitist to be proud of strangers.
                But I am seriously proud of you, if you start to learn programming.
                And I won’t blame you if you stop.
            </p>
            <p>
                But if you <i>do</i> stop, please do it with an affirmative “programming isn’t for me right now. Maybe
                later”.
                That is
                the only way to realise that you actually stopped. You failed. And that’s great! But learn from your
                mistakes.
                Consider why you stopped and what you could have done to keep up motivation. And then use those insights
                the
                next time. I’ll be waiting right here when you are ready to try again.
            </p>
            <p>
                I’m a developer.
                I used worked on games
                <a className='simple-link' href='https://apps.apple.com/us/app/lost-tracks/id1029847339'>Lost Tracks</a>
                 and
                <a className='simple-link' href='https://store.steampowered.com/app/671440/Rise_of_Industry/'>Rise of industry</a>.
                Now I work in a startup.
            </p>
            <p>
                I love to learn.
                Especially these last two years I have tried a lot of new things.
                Improv comedy (learn-to-fail classes).
                Photography (Composition).
                Painting (Learn to see).
                Bass guitar (I failed my first try, but will try again soon!).
                Videos<a className='simple-link' href='https://www.youtube.com/watch?v=UVQsZmdKuBw'>(Here)</a>
                Ceramics<a className='simple-link' href='https://bk-keramik.dk'>(bk-keramik.dk)</a>.
                Yo-Yo (didn’t learn anything except yo-yo).
            </p>
            <p>
                In the future I really want to try farming a field. I also want to learn how to pick locks. I want to
                learn
                a lot of things. But all this all stems from the possibility of being able to support myself with
                coding.
                And that’s my latest and most passionate project. I want to help you learn programming.
            </p>
            <Link to='/lessons/1'>Learn programming</Link>
        </div>
    )
}
