import React, {useState} from "react";
import './choice-quiz.css'

export default function (props) {
    const {question, options, correctAnswers, multipleChoice} = props

    const [selected, setSelected] = useState([])
    const [correct, setCorrect] = useState(false)

    function optionClicked(val) {
        if (correct) return
        if (selected.indexOf(val) !== -1) {
            setSelected(selected.filter(s => s !== val))
        } else {
            setSelected(selected.concat(val))
        }
    }

    function checkCorrect() {
        if (selected.length !== correctAnswers.length) return false
        if (correctAnswers.every(ca => selected.indexOf(ca) !== -1)) {
            setCorrect(true)
        }
    }

    return (<div className={`choice-quiz ${correct && 'correct'}`}>
        <h3 className={`question ${correct && 'correct'}`}>{question}</h3>
        <ul className='options-wrapper'>
            {options.map((opt, idx) => {
                return (<li
                    key={opt}
                    onClick={() => optionClicked(opt)}
                    className={`option ${(selected.indexOf(opt) !== -1) && 'selected'}
                                    ${correct && 'correct'}`}>
                    {<span className='index'>{idx+1}</span>}{opt}
                </li>)
            })}
        </ul>
        <div className='btn-wrap'>
            {
                correct ? <p>Yep!</p> :
                    <input onClick={checkCorrect} type='button' value='Submit'/>
            }
        </div>
    </div>)
}
