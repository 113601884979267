import React from 'react'
import './landing-page.css'
import './lesson-style.css'
import Lesson1 from "../lessons/lesson1";
import Lesson2 from "../lessons/lesson2";
import Lesson3 from "../lessons/lesson3";
import Lesson35 from "../lessons/lesson35";
import Lesson4 from "../lessons/lesson4/lesson4";
import Lesson5 from '../lessons/lesson5';
import {Link} from "react-router-dom";

export default function (props) {
    const lessons = [{
        title: 'Lesson 1',
        obj: <Lesson1/>
    }, {
        title: 'Lesson 2',
        obj: <Lesson2/>
    }, {
        title: 'Lesson 3',
        obj: <Lesson3 />
    }, {
        title: 'Lesson 3.5',
        obj: <Lesson35 />
    }, {
        title: 'Lesson 4',
        obj: <Lesson4 />
    }, {
        title: 'Lesson 5',
        obj: <Lesson5 />
    }]
    window.scrollTo({top: 0, behavior: 'auto'});
    let routedLessonIdx = Number(props.match.params.lessonId) || 1
    routedLessonIdx--

    let previousLesson, nextLesson, currLesson
    if (routedLessonIdx < 0 || routedLessonIdx >= lessons.length) {
        previousLesson = false
        nextLesson = false
        currLesson = {
            'title': 'Not found',
            obj: (<div>
                <p>
                    Can't find this lesson. Maybe try one of the other?<br/>
                    If it was a deadlink, please notify me!
                </p>
            </div>)
        }
    } else {
        previousLesson = routedLessonIdx > 0
        nextLesson = lessons.length > (routedLessonIdx + 1)
        currLesson = lessons[routedLessonIdx]
    }
    const lessonView = (<div className='skip-wrapper'>
        {previousLesson && <Link className='lesson-btn' to={`/lessons/${routedLessonIdx}`}>{lessons[routedLessonIdx - 1].title}</Link>}
        {nextLesson && <Link className='lesson-btn' to={`/lessons/${routedLessonIdx+2}`}>{lessons[routedLessonIdx + 1].title}</Link>}
    </div>)
    return (<div className='LandingPage'>
            {lessonView}
            {currLesson.obj}
            {lessonView}
            <p>
                You want to learn about when new lessons come? Write me an email! Information can be found in the footer
            </p>
        </div>
    )
}
