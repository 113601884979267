import React from 'react'
import MultipleChoiceQuiz from "../quiz/ChoiceQuiz";
import FunctionDescription from "../function-description/FunctionDescription";

export default function (props) {
    return (<div>
        <h1>Lesson 3: Introduction to code</h1>
        <p>
            Let's step into the meat of things, and give the abstract ideas a timeout.
            In this lesson we will introduce functions and variables!
        </p>
        <h2>Functions</h2>
        <p>
            Until now, Walt has worked on 'recipes'.
            In the programming world, this is called a function.
            We are creating <i>new</i> functionality from existing.
        </p>
        <p>
            But the metaphor was very accurate. Just like recipes, a function is a list of commands.
        </p>
        <p>
            We can create functions from commands, thereby creating a layer of abstraction.
            It allows us to reason about the name of a function, instead of the 'mechanics'.
        </p>
        <p>
            Imagine that you are in the middle of a story and you forget the term 'grocery shopping'.
            You were just talking to your friend about something crazy that happened, and then you stop. Because.. God
            damn it. What is it called?
            <i>You know the thing where you go to the store and take a basket, put things in and pay? You know at the
                mall
                or whatever!</i>
        </p>
        <p>
            That explanation is the equivalent of <i>not</i> using functions.
            You have to explain the process instead of just saying "grocery shopping".

        </p>
        <p>
            We define a function to 'wrap' explanations that make sense to us.
            Can you feel the power in that? Being able to create new terms and then reason about them.
        </p>
        <p>
            <b> Power.</b>
        </p>
        <h3>Functions are 'called'</h3>
        <p>
            Functions need to be 'called' before they 'run'.
            For example to start Walt, we would just ask him nicely I guess.
            That's the equivalent of 'Calling a function' in programming.
        </p>
        <p>
            In lesson 1 we created a function (sub-recipe) for adding an ingredient.
            It contained eight or nine lines of code.
            Inside that code there were two bits of information we had to change when using the recipe.
            The amount and what ingredient it was. For example "flour" and "200 grams".
        </p>
        <p>
            That is called 'parameters'.
            Think of it like the context in which we run the function.
            Like a shopping list for our grocery shopping.
        </p>
        <h3>Functions also 'return'</h3>
        <p>
            When a function is called, it will run, and then it will return some value.
            Like returning with groceries after grocery shopping.
        </p>
        <p>
            Not all functions has a return value, but all functions return. I'll explain this concept now.
        </p>
        <h3>A code example</h3>
        <p>
            Let's pretend we have coded a shopping app.
            And we want to display which groceries our user has in their cart.
        </p>
        <p>
            We create a function called 'getGroceriesFromCart' which has a bunch of commands.
            The function is already done, so we don't care about the mechanics.
        </p>
        <p>
            We have no parameters for the function call.
            Because the function does not need any context to perform the task.
        </p>
        <p>
            The return value will be a list of items, currently in the users cart.
        </p>
        <FunctionDescription
            name='getGroceriesFromCart'
            parameters={['Nothing']}
            returnValue={'A list of groceries'}
            description={'Returns the contents of the users cart'}
        />
        <h2>Fun example to make remembering easier</h2>
        <p>
            For a real life example, consider your bread toaster. Which of these are true statements about your toaster:
        </p>
        <ul>
            <li>The number on the dial describes how many minutes to run</li>
            <li>The number describes how hot the toaster gets</li>
        </ul>
        <p>
            I don’t know. And you probably don’t either. Doesn’t matter really.
        </p>
        <p>
            All we care about is how toasted we want the bread on a scale from 1 to 5.
            I don’t care about the reason it becomes more toasted, I just care that I can control it.
        </p>

        <FunctionDescription
            name='toaster'
            parameters={['A number between 1 and 5']}
            returnValue={'Toasted bread'}
            description={'Toasts the bread more, the higher the number is'}
        />

        <h3>Built-in functions</h3>
        <p>
            We don't have to write all the functions ourselves.
            Built-in functions are prepackaged into the programming language.
            Neat.
        </p>
        <p>
            'print' is the most important and probably the most used function of them all:
        </p>

        <FunctionDescription name='print'
                             parameters={['Text or number']}
                             action='Output the received data to the console'
                             returnValue='Nothing'
        />
        <p>
            The built-in function <span className='function'>print</span> doesn’t calculate anything, it simply outputs
            text to the console.
        </p>
        <p>

        </p>
        <h3>The syntax of functions</h3>
        <p>
            To call a function in python: type its name and add () to it.
            If you want to run <span className='function'>print</span> you write:
        </p>
        <p className='code'><span className='function'>print</span>()</p>

        <p>
            To pass parameters (the context of the function), we put them inside the parentheses.
        </p>

        <p className='code'><span className='function'>print</span>(‘Hello’)</p>

        <p>
            That’s all it takes to call a function.
            We can run that program and it will output the word ‘Hello’ to the console and then stop.
        </p>
        <br/>
        <p>
            End of introduction to functions.
            The next step on our journey is ‘Variables’!
        </p>
        <h2>Variable</h2>
        <p>
            If functions are like calls to action, a variable would be the thing being acted upon.
            It's a name we assign a meaning.
        </p>
        <p>
            Functions describe actions. Variables describe the <b>stuff.</b>
        </p>
        <p>
            When we go grocery shopping, we bring a grocery list.
            'grocery list' is a variable.
            We can talk about the list without knowing what is on it.
        </p>
        <p>
            And this way, we can talk about the process of shopping, without knowing what to shop for.
        </p>
        <p>
            For a Walt example.
            Let’s say we want to start using a different kind of flour in our bread.
            Until now we have used wheat, but we want to try rye.
        </p>
        <p>
            We <i>could</i> add a new container to Walts kitchen, that contains rye.
            That would force us to rewrite all our recipes, changing wheat for rye.
            And now we have <b>two</b> books of almost-identical recipes.
            What if we also wanted to try Barley?
        </p>
        <p>
            That's dumb.
            Instead, we use the container of 'flour' as a variable.
        </p>
        <p>
            Before starting Walt, we change the content of the flour container.
            Walt wouldn’t notice the difference, and all your old recipes still work!
        </p>
        <p>
            Just like Walt, computers work on variables.
        </p>

        <h3>Syntax of variables</h3>
        To create a variable, think of any name. Must start with a letter.
        <p className='code'>
            user?
        </p>
        <p>
            Yes! Good.
        </p>
        <p>
            Now think of a value.
        </p>
        <p className='code'>
            'Something cool and clever'
        </p>
        <p>
            Great job buddy. Now add '=' between them.
        </p>
        <p className='code'>
            user = 'Something cool and clever'
        </p>
        <p>
            Just splendid.
        </p>
        <p>
            We now have a new variable called 'user' with the value 'Something cool and clever'.
        </p>
        <p>
            Let's use the variable!
        </p>
        <p className='code'>
            <span className='function'>print</span>(user)
        </p>
        <p>
            Nice.
        </p>
        <h3>An example program that uses all we know!</h3>
        <p>
            Let’s write a program to calculate how many years after Napoleon’s death you were born.
        </p>
        <p>
            The final result of the program should look like this:
        </p>
        <p className='code'>Hello, X Nice to meet you. You were born Y years after Napoleon died!</p>
        <p>
            First we create a variable containing your age.
            If that brings shudders down your back, just use the number 74.
        </p>
        <p className='code'>
            userAge = 28
        </p>
        <p>
            And we also want your name, to add that personal touch.
        </p>
        <p className='code'>
            userName = 'Bjørn'
        </p>
        <p>
            The hard part of the assignment is to find the difference between Napoleon’s death and your birth.
            That would give us how many years later you were born.
        </p>

        <p>
            But we don’t actually know when the user was born.
            We only know how old she is.
            So first, we need to figure out what year she was born.
        </p>
        <p>
            To do <b>that</b> we need to know what year it is right now, and then subtract the user's age.
        </p>
        <p>
            For now, to get going, we are going to make a variable that contains the value 2020, that’s good enough for
            now.
            We will later make the program more future-proof by automating this.
        </p>
        <p>
            So we create a variable called ‘<span className='variable'>currentYear</span>’ and assign it the value 2020.
        </p>
        <MultipleChoiceQuiz
            question='What does that assignment look like?'
            options={['currentYear === 2000 + 20', '2020 = currentYear', 'currentYear = 2020']}
            multipleChoice={false}
            correctAnswers={['currentYear = 2020']}
        />
        <p>
            Awesome.
        </p>
        <p>
            To calculate the year the user was born, we want to create a new variable. We will call it userYearOfBirth.
            The
            value should be the difference between current year and the user’s age.
        </p>
        <MultipleChoiceQuiz
            question='What does that command look like?'
            options={[
                'userBirthYear = userAge - currentYear',
                'userBirthYear = currentYear - userAge',
                'userBirthYear = print(currentYear - userAge)'
            ]}
            correctAnswers={['userBirthYear = currentYear - userAge']}
        />
        <p>
            Lastly we need the year Napoleon died. Since that stays the same, we create a new variable:
        </p>
        <p className='code'><span className='variable'>napoleonYearOfDeath</span> = 1821</p>
        <p>
            Alright. Now we have the <span className='variable'>usersYearOfBirth</span> and <span
            className='variable'>napoleonYearOfDeath</span>.
        </p>
        <p>
            That’s all we need to calculate the difference!
        </p>
        <p className='code'><span className='variable'>differenceInYears</span> = <span
            className='variable'>userYearOfBirth</span> - <span className='variable'>napoleonYearOfDeath</span></p>
        <p>
            Easy squeezy. Final step is to output the result. The wanted result message was:
        </p>
        <p className='code'>“Hello, Name! Nice to meet you. You were born X years after Napoleon died!”</p>
        <p>
            To print that in python, we write:
        </p>
        <p className='code'>
            <span className='function'>print</span>(“Hello, “ + userName + “ Nice to meet you. You were born “
            + <span className='variable'>differenceInYears</span> + “ years after
            Napoleon died!”)
        </p>
        <p>
            The complete code looks like this. Please read it through one line at a time.
        </p>
        <p className='code'>
            <span className='variable'>userName</span> = ‘Bjorn’
            <br/>
            <span className='variable'>userAge</span> = 28
            <br/>
            <span className='variable'>currentYear</span> = 2020
            <br/>
            <span className='variable'>napoleonYearOfDeath</span> = 1821
            <br/>

            <span className='variable'>userYearOfBirth</span> = <span className='variable'>currentYear</span> - <span
            className='variable'>userAge</span>
            <br/>

            <span className='variable'>difference</span> = <span className='variable'>userYearOfBirth</span> - <span
            className='variable'>napoleonYearOfDeath</span>
            <br/>

            <span className='function'>print</span>(“Hello, “ + userName + “ Nice to meet you. You were born “ +
            difference + “ years after Napoleon
            died!”)
        </p>
        <p>
            Our first program. What a ‘beaut! Now let’s get you set up to do this stuff yourself!
        </p>
    </div>)
}
